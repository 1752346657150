<template>
  <div>
    <filter-bar
      v-if="payload.showFilterBar" />

    <div class="flex flex-wrap -mx-4">
      <div
        class="p-4 flex flex-col"
        :class="payload.widthClasses"
        v-for="result in videos"
        :key="result.id">
        <topic-library-card
          v-if="result.resultType === 'topic'"
          :topic="result"
          :image-ratio="payload.imageRatioAsPadding" />

        <podcast-episode-library-card
          v-else-if="result.resultType === 'podcastEpisode'"
          :episode="result"
          :image-ratio="payload.imageRatioAsPadding" />

        <audio-record-library-card
          v-else-if="result.resultType === 'audioRecord'"
          :record="result"
          :image-ratio="payload.imageRatioAsPadding" />

        <video-card
          v-else
          :video="result"
          :image-ratio="payload.imageRatioAsPadding" />
      </div>
    </div>

    <div class="flex justify-center mt-4">
      <button
        v-if="canLoadMore"
        class="btn btn-primary"
        @click.prevent="loadMore"
        :disabled="isLoading">
        <span
          key="loadMoreCta--loading"
          v-if="isLoading">
          <i class="fas fa-spinner fa-spin" />
        </span>
        <span
          key="loadMoreCta--default"
          v-else>
          {{ $t('video.library.loadMore') }}
        </span>
      </button>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import FilterBar from 'components/platform-page/filters/FilterBar';
import AudioRecordLibraryCard from 'components/audios/AudioRecordLibraryCard.vue';
import VideoCard from '../generic/VideoCard.vue';
import TopicLibraryCard from '../topics/TopicLibraryCard.vue';
import PodcastEpisodeLibraryCard from '../podcasts/PodcastEpisodeLibraryCard.vue';

export default {
  props: {
    payload: {
      type: Object,
      required: true
    }
  },
  components: {
    AudioRecordLibraryCard,
    VideoCard,
    TopicLibraryCard,
    PodcastEpisodeLibraryCard,
    FilterBar
  },
  created() {
    this.initStore();
  },
  watch: {
    urlParams() {
      this.search();
    }
  },
  computed: {
    ...mapState('videoSearch', ['isLoading']),
    ...mapGetters('videoSearch', ['videos', 'urlParams', 'canLoadMore']),
  },
  methods: {
    ...mapActions('videoSearch', ['search', 'init', 'loadMore']),
    async initStore() {
      await this.init(this.payload);
      await this.search();
    }
  }
};
</script>
