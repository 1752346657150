<template>
  <li>
    <div>
      <tooltip
        v-if="tooltipContent"
        :content="tooltipContent">
        <i :class="iconClass" />
      </tooltip>

      <i
        v-else
        :class="iconClass" />
    </div>
    <div>
      <slot />
    </div>
  </li>
</template>

<script>
export default {
  props: {
    iconClass: {
      type: String,
      required: true
    },
    tooltipContent: {
      type: String,
      required: false,
      default: null
    }
  }
};
</script>

<style lang="css" scoped>
.t1-videoMeta .t1-videoMeta-list {
  li {
    @apply flex items-start;

    &:not(:last-child) {
      @apply mb-1;
    }

    > div {
      &:first-child {
        width: 24px;
        @apply flex-shrink-0;

        i {
          @apply text-lg;
        }
      }

      &:last-child {
        @apply pl-2;
      }
    }
  }

  &.t1-videoMeta--row {
    ul {
      @apply flex flex-wrap -mx-3 -my-2;

      li {
        @apply mx-3 my-2;

        > div {
          &:first-child {
            width: auto;
          }
        }
      }
    }
  }
}
</style>
