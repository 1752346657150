import Velocity from 'velocity-animate';

export default {
  methods: {
    enterAnimation(el, done) {
      Velocity(el, 'slideDown', {
        duration: 250,
        complete() {
          done();
        }
      });
    },
    leaveAnimation(el, done) {
      Velocity(el, 'slideUp', {
        duration: 250,
        complete() {
          done();
        }
      });
    }
  }
};
