import Vue from 'vue';

import MultiSelectFilter from './MultiSelectFilter.vue';

export default Vue.component('FilterElement', {
  functional: true,
  props: {
    config: {
      type: Object,
      required: true
    }
  },
  render(h, context) {
    // TODO load different filter components depending on the type (if there are multiple types)
    // const { type } = context.props.config;
    const child = MultiSelectFilter;

    return h('div', {
      attrs: {
        class: context.data.staticClass
      }
    }, [
      h(child, context)
    ]);
  }
});
