import axios from 'axios';
import * as types from './mutation-types';

export default {
  setMember({ commit }, member) {
    commit(types.SET_MEMBER, member);
  },
  showLogin({ commit }) {
    commit(types.SET_LOGIN_VISIBILITY, true);
  },
  hideLogin({ commit, dispatch }) {
    commit(types.SET_LOGIN_VISIBILITY, false);
    dispatch('generic/setSelectedVideo', null, { root: true });
  },
  async login({ rootState, commit }, { email, password }) {
    const response = await axios
      .post(`${location.origin}/user/login`, {
        email,
        password,
        formSecurityToken: rootState.generic.formSecurityToken
      });

    commit(types.SET_MEMBER, response.data);
  },
  async logout({ rootState, commit }) {
    try {
      await axios
        .post(`${location.origin}/user/logout`, {
          formSecurityToken: rootState.generic.formSecurityToken
        });

      commit(types.SET_MEMBER, null);
      location.reload();
    } catch (e) {
      // noop
    }
  },
  setLoginMessage({ commit }, message) {
    commit(types.SET_LOGIN_MESSAGE, message);
  },
  clearLoginMessage({ dispatch }) {
    dispatch('setLoginMessage', null);
  },
  setLoginSessionMessage(_, message) {
    try {
      sessionStorage.setItem('t1-loginMessage', JSON.stringify(message));
    } catch (e) {
      // noop
    }
  },
};
