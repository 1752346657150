export default {
  isConsentModeActive(state) {
    return state.cookieNotice && state.cookieNotice.content && state.cookieNotice.categories;
  },
  inBaseView(state) {
    return state.view === 'base';
  },
  inCategoriesView(state) {
    return state.view === 'categories';
  },
  inDetailsView(state) {
    return state.view === 'details';
  },
  isClosable(state) {
    return state.userSelected;
  },
  currentConsent(state, getters) {
    if (getters.isConsentModeActive) {
      return Object.keys(state.cookieNotice.categories)
        .reduce((map, categoryCode) => { map[categoryCode] = state.cookieNotice.categories[categoryCode].selected; return map; }, {});
    }

    return null;
  }
};
