<script>
export default {
  props: {
    artistPage: {
      type: Object,
      required: true,
    }
  },
  computed: {
    hasSocialMediaInfo() {
      return this.artistPage.socialMediaInfo
        && (
          this.artistPage.socialMediaInfo.website
          || this.artistPage.socialMediaInfo.twitter
          || this.artistPage.socialMediaInfo.facebook
        );
    }
  }
};
</script>

<template>
  <modal
    @close="$emit('close')">
    <div class="t1-modalContent t1-artistPageModal">
      <h2 class="pb-6">
        {{ artistPage.artistPageTitle }}
      </h2>

      <div>
        <ul>
          <li
            v-if="artistPage.nationality">
            <span class="font-bold">{{ $t('video.artistPage.nationality') }}:</span> {{ artistPage.nationality }}
          </li>

          <li v-if="artistPage.instrument">
            <span class="font-bold">{{ $t('video.artistPage.instrument') }}:</span> {{ artistPage.instrument }}
          </li>

          <li v-if="artistPage.dateOfBirth">
            <span class="font-bold">{{ $t('video.artistPage.dateOfBirth') }}:</span> {{ artistPage.dateOfBirth }}
          </li>

          <li v-if="artistPage.dateOfDeath">
            <span class="font-bold">{{ $t('video.artistPage.dateOfDeath') }}:</span> {{ artistPage.dateOfDeath }}
          </li>

          <template v-if="hasSocialMediaInfo">
            <li v-if="artistPage.socialMediaInfo.website">
              <span class="font-bold">
                {{ $t('video.artistPage.website') }}:</span> <a
                :href="artistPage.socialMediaInfo.website"
                target="_blank"
                rel="noopener nofollow">
                {{ artistPage.socialMediaInfo.website }}
              </a>
            </li>

            <li v-if="artistPage.socialMediaInfo.twitter">
              <i class="fab fa-twitter mr-1" /> <a
                :href="artistPage.socialMediaInfo.twitter.url"
                target="_blank"
                rel="noopener nofollow">
                {{ artistPage.socialMediaInfo.twitter.handle }}
              </a>
            </li>

            <li v-if="artistPage.socialMediaInfo.facebook">
              <i class="fab fa-facebook-f mr-1" /> <a
                :href="artistPage.socialMediaInfo.facebook.url"
                target="_blank"
                rel="noopener nofollow">
                {{ artistPage.socialMediaInfo.facebook.handle }}
              </a>
            </li>
          </template>
        </ul>

        <div
          class="t1-editorContent"
          v-if="artistPage.artistPage && artistPage.artistPage.content">
          <div class="font-bold">
            {{ $t('video.artistPage.description') }}:
          </div>
          <div v-html="artistPage.artistPage.content" />
        </div>
      </div>
    </div>
  </modal>
</template>

<style lang="css" scoped>
.t1-artistPageModal {
  ul li {
    @apply mb-2;
  }
}
</style>
