<template>
  <div
    class="t1-topicCard t1-mediaLibraryCard relative border border-mono-90 flex-grow"
    @mouseenter="hovered = true"
    @mouseleave="hovered = false">
    <div
      class="lazyload t1-mediaLibraryCard-image"
      :data-bg="image"
      :title="imageCredits"
      :class="{'t1-mediaLibraryCard--noThumbnail': !image}"
      :style="{ paddingBottom: imageRatio }">
      <i
        class="fa fa-book-open"
        v-if="!image" />

      <a :href="topic.link" />
    </div>

    <a
      class="t1-mediaLibraryCard-overlay"
      :href="topic.link">
      <div class="t1-mediaLibraryCard-overlay-base">
        <div class="font-bold pr-4">
          <a>
            {{ cardTitle }}
          </a>
        </div>

        <div class="t1-mediaLibraryCard-overlay-cta bg-primary text-primary-contrast">
          <i class="icon-takt1_arrow" />
        </div>
      </div>
    </a>
  </div>
</template>

<script>
export default {
  props: {
    topic: {
      type: Object,
      required: true
    },
    imageRatio: {
      type: String,
      required: false,
      default: '56.25%'
    }
  },
  data() {
    return {
      hovered: false,
    };
  },
  computed: {
    image() {
      return this.topic.previewImage && this.topic.previewImage.url
        ? this.topic.previewImage.url
        : null;
    },
    imageCredits() {
      return this.topic.previewImage && this.topic.previewImage.credits
        ? this.topic.previewImage.credits
        : null;
    },
    cardTitle() {
      return `${this.$t('topicLibraryCard.titlePrefix')}: ${this.topic.title}`;
    }
  },
};
</script>
