<template>
  <div class="t1-audioPlayer">
    <audio
      controls
      controlsList="nodownload noplaybackrate">
      <source
        :src="file.link"
        type="audio/mpeg">
      {{ $t('audioPlayer.notSupported') }}
    </audio>
  </div>
</template>

<script>
export default {
  props: {
    file: {
      type: Object,
      required: true
    }
  },
};
</script>
