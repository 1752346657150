import { cloneObject } from 'utils';
import actions from './actions';
import mutations from './mutations';
import getters from './getters';

export const namespaced = true;

const state = {
  cookieNotice: payload.generic && payload.generic.cookieNotice ? cloneObject(payload.generic.cookieNotice) : [],
  view: 'base',
  selectedCategory: null,
  cookieNoticeVisible: false,
  cookieNoticeMessage: null,
  userSelected: false
};

const init = (store) => {
  if (store.getters['cm/isConsentModeActive']) {
    store.dispatch('cm/loadFromStorage');
  } else {
    store.dispatch('cm/disableConsentMode');
  }
};

export {
  getters, actions, mutations, state, init
};
