export default {
  hasAccessCodeRestriction(state) {
    return !!state.restrictions.find((restriction) => restriction.type === 'AccessCodeRestriction');
  },
  hasMemberRestriction(state) {
    return !!state.restrictions.find((restriction) => restriction.type === 'MemberRestriction');
  },
  activeLocale(state) {
    return state.locales.find((locale) => locale.isActive);
  }
};
