<template>
  <div class="video-container">
    <YoutubeVideoPlayer
      v-if="externalMediaEnabled"
      :video-url="videoUrl" />

    <div
      v-if="isConsentModeActive && !externalMediaEnabled"
      class="t1-videoCookieConsentHint">
      <div class="t1-videoCookieConsentHint-description">
        {{ $t('youtubeVideo.consent.description', { consentName: cookieNotice.categories.external_media.name }) }}
      </div>

      <div class="t1-videoCookieConsentHint-actions">
        <button
          class="btn btn-mono-100-outline"
          @click.prevent="acceptSingleCategory('external_media')">
          {{ $t('youtubeVideo.consent.accept') }}
        </button>

        <a
          href=""
          @click.prevent="$store.dispatch('cm/showCookieNotice')">
          {{ $t('youtubeVideo.consent.moreInfo') }}
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import { mapState, mapGetters, mapActions } from 'vuex';
import { externalMediaEnabled$ } from 'utils/consent';

Vue.component('YoutubeVideoPlayer', () => import(/* webpackChunkName: "youtubeVideoPlayer" */ 'components/generic/YoutubeVideoPlayer.vue'));

export default {
  data() {
    return {
      externalMediaEnabled: false
    };
  },
  props: {
    videoUrl: {
      type: String,
      required: true
    }
  },
  created() {
    externalMediaEnabled$.subscribe((enabled) => {
      this.externalMediaEnabled = enabled;
    });
  },
  computed: {
    ...mapState('cm', ['cookieNotice']),
    ...mapGetters('cm', ['isConsentModeActive'])
  },
  methods: {
    ...mapActions('cm', ['acceptSingleCategory'])
  }
};
</script>

<style lang="css">
.video-container {
  .t1-videoCookieConsentHint {
    @apply absolute top-0 left-0 w-full h-full flex justify-center items-center flex-col bg-mono-30 text-mono-100 p-4 text-center;

    .t1-videoCookieConsentHint-description {
      @apply sm:text-2xl;
    }

    .t1-videoCookieConsentHint-actions {
      @apply flex flex-col mt-4;

      > a {
        @apply underline mt-2;
      }
    }
  }
}
</style>
