<template>
  <div
    class="t1-mobileNav">
    <a
      @click.prevent="toggleMobileMenu"
      class="t1-mobileNav-toggle">
      <i class="fas fa-bars" />
    </a>

    <div
      class="t1-mobileNav-content"
      :class="{'t1-mobileNav-content--visible': mobileMenuVisible}">
      <slot name="t1-badge" />
      <div
        class="t1-mobileNav-contentInner"
        :class="{'t1-mobileNav--hasT1Badge': hasT1Badge}">
        <slot name="main-menu" />

        <slot name="login-logout-button" />

        <slot name="sponsor-logos" />

        <slot name="social-media-icons" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
  data() {
    return {
      hasT1Badge: false
    };
  },
  mounted() {
    if (this.$slots['t1-badge'] !== undefined) {
      this.hasT1Badge = true;
    }
  },
  computed: {
    ...mapState('generic', ['mobileMenuVisible'])
  },
  methods: {
    ...mapActions('generic', ['toggleMobileMenu'])
  }
};
</script>

<style lang="css">
  .t1-mobileNav {
    .t1-mobileNav-toggle {
      @apply text-3xl;
    }

    .t1-mobileNav-content {
      @apply fixed right-0 w-full bg-mono-92 transition-transform;
      transform: translateX(100%);
      top: var(--header-height);
      padding-top: var(--logo-overlap);
      box-shadow: 0 6px 6px rgba(0,0,0,0.2);

      .t1-mobileNav-contentInner {
        @apply p-8 relative flex flex-col items-center;

        &.t1-mobileNav--hasT1Badge {
          @apply pt-16;
        }

        > *:not(:last-child) {
          @apply pb-8;
        }

        .t1-mainNav {
          @apply px-0;

          ul {
            @apply flex-col;

            li {
              @apply mx-0 text-center;

              &:not(:last-child) {
                @apply mb-4;
              }
            }
          }
        }

        .t1-loginLogoutButtons {
          @apply mx-auto flex-wrap justify-center;

          > a {
            @apply m-2;
          }
        }
      }

      .t1-badge {
        position: absolute;
        top: 0;
        right: 0;
      }

      &.t1-mobileNav-content--visible {
        transform: translateX(0);
      }
    }
  }
</style>
