<template>
  <div class="t1-msf-options">
    <div
      v-if="showSearch"
      class="t1-msf-search">
      <input
        type="text"
        v-model="term"
        :placeholder="$t('video.library.filter.searchPlaceholder')">
    </div>
    <ul>
      <li
        v-for="option in filteredOptions"
        :key="option.key"
        @click.prevent="toggle(option)"
        :class="[
          { 't1-msf-option--active': option.active },
          { 't1-msf-option--disabled': !option.doc_count }
        ]">
        {{ option.title ? option.title : option.key }}
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    options: {
      type: Array,
      required: true
    },
    showSearch: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      term: ''
    };
  },
  computed: {
    filteredOptions() {
      let options = this.options
        .filter((o) => o.doc_count > 0);

      if (this.term && this.term.length > 0) {
        const term = this.term.toLowerCase();

        options = options
          .filter((o) => (o.title ? o.title : o.key).toLowerCase().indexOf(term) > -1);
      }

      return options;
    }
  },
  methods: {
    toggle(option) {
      this.$emit('toggle', option);
    }
  }
};
</script>

<style lang="css">
.t1-msf-options {
  @apply flex-auto flex flex-col;

  .t1-msf-search {
    @apply w-full;

    input {
      @apply w-full border-0 bg-mono-92;
      @apply outline-none ring-0 !important;
    }
  }

  ul {
    @apply flex-auto overflow-y-auto;
    max-height: 250px;
  }

  li {
    @apply p-2 cursor-pointer select-none hover:bg-mono-92 border-mono-92 transition-colors flex items-center;

    &.t1-msf-option--active {
      &:before {
        @apply bg-primary;
      }
    }

    &.t1-msf-option--disabled {
      @apply opacity-50;
    }

    &:not(:last-child) {
      border-bottom-width: 1px;
      border-style: solid;
    }

    &:before {
      @apply block mr-2 border-2 border-mono-50 transition-colors flex-none;
      content: '';
      width: 12px;
      height: 12px;
      border-radius: 50%;
    }
  }
}
</style>
