import Vue from 'vue';
import CommonPlugin from 'plugins/common-plugin';
import { i18n } from 'utils/i18n';
import blockPage from 'mixins/blockPage';
import MediaLibraryBlock from 'components/platform-page/MediaLibraryBlock';
import 'styles/pages/home-page.css';
import store from 'stores/home-page';

Vue.use(CommonPlugin);

new Vue({
  el: '#app',
  store,
  i18n,
  mixins: [blockPage],
  components: {
    MediaLibraryBlock,
    UpcomingStreamBlock: () => import(/* webpackChunkName: "upcomingStreamBlock" */ 'components/platform-page/UpcomingStreamBlock'),
    VideoSuggestionsBlock: () => import(/* webpackChunkName: "videoSuggestionsBlock" */ 'components/video-page/VideoSuggestionsBlock')
  }
});
