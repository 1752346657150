import Vue from 'vue';
import LoadScript from 'vue-plugin-load-script';
import VueCarousel from 'vue-carousel';
import YoutubeVideo from 'components/generic/YoutubeVideo';
import ThreeqVideoPlayer from 'components/generic/ThreeqVideoPlayer';
import AudioPlayer from 'components/generic/AudioPlayer';
import EmbeddedVideo from 'components/generic/EmbeddedVideo';
import VueScrollTo from 'vue-scrollto';

Vue.use(VueCarousel);
Vue.use(LoadScript);

Vue.component('CommentsBlock', () => import(/* webpackChunkName: "comments-block" */ 'components/generic/CommentsBlock'));

export default {
  components: {
    YoutubeVideo,
    ThreeqVideoPlayer,
    AudioPlayer,
    EmbeddedVideo,
  },
  mounted() {
    this.$children.forEach((vueComponent) => {
      if (vueComponent.$el?.classList?.contains('VueCarousel')) {
        // Dirty hack to ensure the first slide is actually marked active
        if (vueComponent.slideCount > 1) {
          vueComponent.currentPage = 1;
          vueComponent.currentPage = 0;
        }
      }
    });

    this.handleLocationHash();
  },
  methods: {
    async handleLocationHash() {
      const { hash } = location;

      if (hash && typeof hash === 'string') {
        const el = document.getElementById(hash.slice(1));

        if (el) {
          setTimeout(() => {
            this.scrollTo(el);
          }, 400);
        }
      }
    },
    scrollToId(id) {
      const el = document.getElementById(id);

      if (el) {
        this.scrollTo(el);
      }
    },
    scrollTo(dest) {
      const header = document.getElementById('t1-mainHeader');

      VueScrollTo.scrollTo(dest, {
        offset: header ? -(header.clientHeight) : 0,
      });
    }
  }
};
