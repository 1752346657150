import { timeout } from 'src/utils/index';

export default class VideoPlayer {
  constructor() {
    this.js3q = null;
    this.playing = false;
    this.duration = 0;
    this.time = 0;
  }

  /**
   * Init 3Q player.
   *
   * @param {{}} options See https://docs.3q.video/en/Player/Configuration.html
   * @return {Promise<unknown>}
   */
  async init(options) {
    // eslint-disable-next-line new-cap
    this.js3q = new js3q(options);

    return new Promise((resolve) => {
      this.addListeners();
      this.js3q.once('media.ready', () => {
        this.duration = this.js3q.getDuration();
        resolve();
      });
    });
  }

  addListeners() {
    this.js3q.on('media.playing', () => {
      this.playing = true;
    });
    this.js3q.on('media.paused', () => {
      this.playing = false;
    });
    this.js3q.on('media.complete', () => {
      this.playing = false;
    });
    this.js3q.on('media.durationChange', (data) => {
      this.duration = data;
    });
    this.js3q.on('media.timeChange', (data) => {
      this.time = data;
    });
  }

  async play() {
    return new Promise((resolve) => {
      this.js3q.once('media.playing', async () => {
        await timeout(1000);
        resolve();
      });
      this.js3q.play();
    });
  }

  async pause() {
    return new Promise((resolve) => {
      this.js3q.once('media.paused', () => {
        resolve();
      });
      this.js3q.pause();
    });
  }

  async seek(seconds) {
    if (!this.playing) {
      await this.play();
    }

    this.js3q.seek(seconds);
  }
}
