<template>
  <div
    class="t1-msf"
    :class="[
      { 't1-msf--open': isOpen },
      { 't1-msf--selected': activeOptions.length > 0 },
      { 't1-msf--disabled': !isAvailable }
    ]">
    <div
      class="t1-msf-toggle"
      @click.prevent="toggle">
      <div class="flex-auto">
        <div
          class="font-bold">
          {{ title }}
        </div>

        <div
          v-if="activeOptions.length > 0"
          class="text-xs">
          {{ $t('video.library.filter.selected', { count: activeOptions.length }) }}
        </div>
      </div>

      <span class="t1-msf-toggleIndicator text-primary">
        <i class="icon-takt1_accordion_filter_arrow" />
      </span>
    </div>

    <div
      v-if="isOpen"
      class="t1-msf-content">
      <filter-options
        :options="internalOptions"
        @toggle="toggleOption"
        :show-search="showSearch" />

      <div class="t1-msf-contentFooter">
        <button
          class="t1-msf-resetBtn"
          :disabled="!canReset"
          @click.prevent="reset">
          {{ $t('video.library.filter.reset') }}
        </button>
        <button
          class="t1-msf-saveBtn transition-colors bg-primary hover:bg-primary-darker text-primary-contrast"
          :disabled="!canSave"
          @click.prevent="save">
          {{ $t('video.library.filter.save') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import dropdownMixin from 'mixins/dropdown';
import { cloneObject } from 'utils';
import FilterOptions from './MultiSelectFilterOptions.vue';

export default {
  mixins: [dropdownMixin],
  components: { FilterOptions },
  props: {
    config: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      containerClass: 't1-msf',
      internalOptions: null
    };
  },
  computed: {
    ...mapState('videoSearch', ['filterOptions', 'filterConfig']),
    ...mapGetters('videoSearch', ['aggregations', 'urlParams']),
    title() {
      return this.config.title;
    },
    param() {
      return this.config.key;
    },
    showSearch() {
      return this.config.showSearch;
    },
    activeOptions() {
      if (this.urlParams && this.urlParams[this.param]) {
        const options = this.urlParams[this.param];

        return typeof options === 'string' || typeof options === 'number' ? [options] : options;
      }

      return [];
    },
    optionLabels() {
      if (this.config?.options && Array.isArray(this.config.options)) {
        const options = {};
        this.config.options.forEach((option) => {
          options[option.code] = option.title;
        });

        return options;
      }

      return null;
    },
    options() {
      if (!this.filterOptions || !this.aggregations) return [];
      let options = [];

      const getOptions = (key) => this.filterOptions[key].map((option) => {
        const result = {
          key: option.key,
          doc_count: 0
        };

        if (this.aggregations[key]) {
          const agg = this.aggregations[key].find((o) => o.key === option.key);

          if (agg) {
            result.doc_count = agg.doc_count;
          }
        }

        return result;
      });

      if (this.param === 'artist') {
        ['conductor', 'soloist', 'choir', 'ensemble'].forEach((key) => {
          if (this.filterOptions[key] && Array.isArray(this.filterOptions[key])) {
            options = [
              ...options,
              ...getOptions(key)
            ];
          }
        });
      } else if (this.filterOptions[this.param]) {
        options = getOptions(this.param);
      }

      options.forEach((item) => {
        item.active = this.activeOptions.includes(item.key);
        item.title = this.optionLabels?.[item.key];
      });

      return options;
    },
    canReset() {
      if (this.activeOptions.length > 0) {
        return true;
      }

      return this.internalOptions.filter((o) => o.active).length > 0;
    },
    isDirty() {
      const prev = this.options
        .filter((o) => o.active)
        .map((o) => o.key)
        .sort();

      const now = this.internalOptions
        .filter((o) => o.active)
        .map((o) => o.key)
        .sort();

      return JSON.stringify(prev) !== JSON.stringify(now);
    },
    canSave() {
      return this.isDirty;
    },
    optionsWithResults() {
      return this.options.filter((o) => o.doc_count > 0);
    },
    isAvailable() {
      return this.optionsWithResults.length > 0;
    }
  },
  methods: {
    ...mapActions('videoSearch', ['setUrlParam']),
    open() {
      this.internalOptions = cloneObject(this.options);
      this.isOpen = true;
      this.setActiveDropDownFilter(this._uid);
    },
    closeOnClickOutside() {
      this.save();
    },
    toggleOption(option) {
      if (this.config.type === 'select') {
        this.internalOptions.forEach((o) => { o.active = false; });
      }

      option.active = !option.active;

      if (this.config.type === 'select') {
        this.save();
      }
    },
    save() {
      const activeOptions = this.internalOptions
        .filter((o) => o.active)
        .map((o) => o.key);

      this.setUrlParam(
        {
          key: this.param,
          value: activeOptions.length > 0
            ? activeOptions
            : null
        }
      );

      this.close();
    },
    reset() {
      this.internalOptions.forEach((o) => {
        o.active = false;
      });
      this.save();
    }
  }
};
</script>

<style lang="css">
.t1-msf {
  @apply relative m-2;

  .t1-msf-toggle {
    @apply
    flex
    px-4
    py-4
    transition-colors
    bg-mono-92
    hover:bg-mono-88
    flex
    justify-between
    items-center
    leading-none
    cursor-pointer
    select-none;

    width: 200px;

    .t1-msf-toggleIndicator {
      @apply transition-transform ml-4 text-xs flex;
    }
  }

  &.t1-msf--selected {
    .t1-msf-toggle {
      @apply py-2;
    }
  }

  &.t1-msf--open {
    .t1-msf-toggle {
      @apply bg-mono-88;

      .t1-msf-toggleIndicator {
        transform: rotate(-180deg);
      }
    }
  }

  &.t1-msf--disabled {
    @apply pointer-events-none opacity-50;
  }

  .t1-msf-content {
    @apply
    absolute
    z-50
    top-full
    left-0
    overflow-hidden
    flex
    flex-col
    bg-mono-100
    shadow
    w-full;

    .t1-msf-contentHeader {
      @apply flex-none text-right p-2 mb-2 border-b-2 border-mono-90;
    }

    .t1-msf-contentFooter {
      @apply flex-none mt-2 flex;

      > button {
        @apply p-2 w-1/2 text-xs;
        @apply outline-none !important;

        &[disabled] {
          @apply opacity-40 cursor-default;
        }
      }

      .t1-msf-resetBtn {
        @apply transition-colors bg-mono-92 hover:bg-mono-88;
      }

      .t1-msf-saveBtn {
      }
    }
  }
}
</style>
