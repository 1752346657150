import * as types from './mutation-types';

export default {
  addToOverlayStack({ commit }, uid) {
    commit(types.ADD_TO_OVERLAY_STACK, uid);
  },
  removeFromOverlayStack({ commit }, uid) {
    commit(types.REMOVE_FROM_OVERLAY_STACK, uid);
  },
  showRestrictionModal({ dispatch, rootGetters }, video = null) {
    dispatch('setSelectedVideo', video);

    if (rootGetters['generic/hasMemberRestriction']) {
      dispatch('member/showLogin', null, { root: true });
    } else if (rootGetters['generic/hasAccessCodeRestriction']) {
      dispatch('showAccessCodeModal');
    }
  },
  setAccessCodeModalMessage({ commit }, message) {
    commit(types.SET_ACCESS_CODE_MODAL_MESSAGE, message);
  },
  clearAccessCodeModalMessage({ dispatch }) {
    dispatch('setAccessCodeModalMessage', null);
  },
  showAccessCodeModal({ commit }) {
    commit(types.SET_ACCESS_CODE_MODAL_VISIBILITY, true);
  },
  hideAccessCodeModal({ commit, dispatch }) {
    commit(types.SET_ACCESS_CODE_MODAL_VISIBILITY, false);
    dispatch('setSelectedVideo', null);
  },
  setSelectedVideo({ commit }, video) {
    commit(types.SET_SELECTED_VIDEO_PAGE, video);
  },
  setAccessCodeModalSessionMessage(_, message) {
    try {
      sessionStorage.setItem('t1-accessCodeModalMessage', JSON.stringify(message));
    } catch (e) {
      // noop
    }
  },
  setActiveDropDownFilter({ commit }, filter) {
    commit(types.SET_ACTIVE_DROPDOWN_FILTER, filter);
  },
  toggleMobileMenu({ state, dispatch }) {
    if (state.mobileMenuVisible) {
      dispatch('hideMobileMenu');
    } else {
      dispatch('showMobileMenu');
    }
  },
  showMobileMenu({ commit }) {
    commit(types.SET_MOBILE_MENU_VISIBILITY, true);

    if (document.getElementById('t1-mainHeader')) {
      const headerHeight = document.getElementById('t1-mainHeader').clientHeight;
      const logoHeight = document.getElementById('t1-mainLogo').clientHeight;

      let logoOverlap = logoHeight - headerHeight;
      logoOverlap = logoOverlap > 0 ? `${logoOverlap}px` : 0;

      document.documentElement.style.setProperty('--header-height', `${headerHeight}px`);
      document.documentElement.style.setProperty('--logo-overlap', logoOverlap);
    }
  },
  hideMobileMenu({ commit }) {
    commit(types.SET_MOBILE_MENU_VISIBILITY, false);
  }
};
