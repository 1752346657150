import Cookies from 'js-cookie';
import { analyticsEnabled$, externalMediaEnabled$, socialMediaEnabled$ } from 'utils/consent';
import { cloneObject } from 'utils';
import * as types from './mutation-types';

export default {
  saveConsent({ dispatch }, consent) {
    Cookies.set('cookieConsent', JSON.stringify(consent), { expires: 30 });
    dispatch('setConsent', consent);
    dispatch('hideCookieNotice');
  },
  setConsent({ commit }, consent) {
    commit(types.UPDATE_CONSENT, consent);
    commit(types.SET_USER_SELECTED_STATE, true);

    analyticsEnabled$.next(!!(consent.analytics && consent.analytics === true));
    externalMediaEnabled$.next(!!(consent.external_media && consent.external_media === true));
    socialMediaEnabled$.next(!!(consent.social_media && consent.social_media === true));
  },
  disableConsentMode({ commit }) {
    commit(types.SET_USER_SELECTED_STATE, true);
    analyticsEnabled$.next(true);
    externalMediaEnabled$.next(true);
    socialMediaEnabled$.next(true);
  },
  acceptAll({ state, dispatch }) {
    const consent = Object.keys(state.cookieNotice.categories)
      .reduce((map, categoryCode) => { map[categoryCode] = true; return map; }, {});

    dispatch('saveConsent', consent);
  },
  acceptSingleCategory({ getters, dispatch }, category) {
    const consent = cloneObject(getters.currentConsent);
    consent[category] = true;
    dispatch('saveConsent', consent);
  },
  denyAll({ state, dispatch }) {
    const consent = Object.keys(state.cookieNotice.categories)
      .reduce(
        (map, categoryCode) => {
          map[categoryCode] = categoryCode === 'technical';
          return map;
        },
        {}
      );

    dispatch('saveConsent', consent);
  },
  changeView({ commit }, view) {
    commit(types.CHANGE_VIEW, view);
  },
  showCategoriesView({ dispatch }) {
    dispatch('changeView', 'categories');
  },
  selectCategory({ commit }, category) {
    commit(types.SELECT_CATEGORY, category);
  },
  showDetails({ dispatch }, category) {
    dispatch('selectCategory', category);
    dispatch('changeView', 'details');
  },
  closeDetails({ dispatch }) {
    dispatch('selectCategory', null);
    dispatch('changeView', 'categories');
  },
  showCookieNotice({ commit }) {
    commit(types.SET_COOKIE_NOTICE_VISIBILITY, true);
  },
  showCookieNoticeWithMessage({ dispatch }, message) {
    dispatch('setCookieNoticeMessage', message);
    dispatch('showCookieNotice');
  },
  hideCookieNotice({ commit, dispatch }) {
    commit(types.SET_COOKIE_NOTICE_VISIBILITY, false);
    dispatch('clearCookeNoticeMessage');
  },
  setCookieNoticeMessage({ commit }, message) {
    commit(types.SET_COOKIE_NOTICE_MESSAGE, message);
  },
  clearCookeNoticeMessage({ dispatch }) {
    dispatch('setCookieNoticeMessage', null);
  },
  loadFromStorage({ state, commit, dispatch }) {
    if (Cookies.get('cookieConsent')) {
      try {
        const consent = JSON.parse(Cookies.get('cookieConsent'));

        if (!consent || typeof consent !== 'object') {
          throw new Error('invalid_consent_format');
        }

        const consentKeys = Object.keys(consent);
        const validKeys = Object.keys(state.cookieNotice.categories);
        const diff = consentKeys
          .filter((k) => !validKeys.includes(k))
          .concat(validKeys.filter((k) => !consentKeys.includes(k)));

        if (diff.length > 0) {
          throw new Error('invalid_consent_keys');
        }

        dispatch('setConsent', consent);
      } catch (e) {
        Cookies.remove('cookieConsent');
        dispatch('showCookieNotice');
        commit(types.SET_USER_SELECTED_STATE, false);
      }
    } else {
      dispatch('showCookieNotice');
    }
  }
};
