import { DateTime } from 'luxon';
import { mapState } from 'vuex';

export default {
  computed: {
    ...mapState('generic', ['theme']),
    previewImage() {
      return this.video.previewImage && this.video.previewImage.url
        ? this.video.previewImage.url
        : null;
    },
    previewImageCredits() {
      return this.video.previewImage && this.video.previewImage.credits
        ? this.video.previewImage.credits
        : null;
    },
    title() {
      return this.video.title;
    },
    subTitle() {
      return this.video.subTitle;
    },
    cardTitle() {
      if (this.theme?.videos?.useCombinedTitleInCards && this.title && this.subTitle) {
        return `${this.title}: ${this.subTitle}`;
      }

      return this.title;
    },
    isLiveStream() {
      return this.video.isLiveStream;
    },
    startDate() {
      if (this.video.startDate) {
        return DateTime.fromFormat(this.video.startDate, 'yyyy-MM-dd HH:mm:ss', { zone: 'utc' });
      }

      return undefined;
    },
    streamStartDate() {
      const date = this.video.streamingStart
        ? this.video.streamingStart
        : this.video.startDate;

      if (date) {
        return DateTime.fromFormat(date, 'yyyy-MM-dd HH:mm:ss', { zone: 'utc' });
      }

      return null;
    },
    streamEndDate() {
      return this.video.endDate
        ? DateTime.fromFormat(this.video.endDate, 'yyyy-MM-dd HH:mm:ss', { zone: 'utc' })
        : null;
    },
    hasLivestreamEnded() {
      return this.streamEndDate && this.streamEndDate < DateTime.utc();
    },
    inPreMode() {
      if (!this.isLiveStream) {
        return false;
      }

      if (!this.streamStartDate) {
        return true;
      }

      return this.streamStartDate > DateTime.utc();
    },
    inLiveMode() {
      if (!this.isLiveStream) {
        return false;
      }

      if (!this.streamStartDate) {
        return false;
      }

      return this.streamStartDate <= DateTime.utc()
        && (
          !this.streamEndDate
          || this.streamEndDate > DateTime.utc()
        );
    },
    inWaitingMode() {
      return this.hasLivestreamEnded && !this.video.playoutId;
    },
    inVoDMode() {
      return this.hasLivestreamEnded && !!this.video.playoutId;
    },
    liveStreamMode() {
      if (this.inPreMode) return 'pre';
      if (this.inLiveMode) return 'live';
      if (this.inWaitingMode) return 'waiting';
      return 'vod';
    }
  }
};
