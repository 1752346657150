import Vue from 'vue';
import * as Sentry from '@sentry/browser';
import * as Integrations from '@sentry/integrations';
import pkg from 'root/package.json';

const config = payload.sentry;
const useSentry = config.enabled;

/**
 * Logging helper class, using Sentry on production.
 *
 * @see https://docs.sentry.io/platforms/javascript/vue/
 */
export default {
  init() {
    if (useSentry) {
      Sentry.init({
        dsn: config.dsn,
        release: `${pkg.name}@${pkg.version}`,
        environment: config.environment,
        normalizeDepth: 6,
        integrations: [
          new Integrations.Vue({
            Vue,
            attachProps: true,
          }),
        ],
        beforeSend(event, hint) {
          if (event
            && event.exception
            && event.exception.values
            && Array.isArray(event.exception.values)
            && event.exception.values.length > 0) {
            const exceptionValue = event.exception.values[0];
            if (exceptionValue && exceptionValue.value && typeof exceptionValue.value === 'string') {
              if (exceptionValue.value.indexOf('ResizeObserver') > -1) {
                return null;
              }
            }
          }

          return event;
        },
      });

      if (config.tags
        && Object.keys(config.tags).length > 0) {
        Sentry.setTags(config.tags);
      }
    }
  },
  /**
   * Capture an exception.
   *
   * @param err
   * @param captureContext @see https://docs.sentry.io/platforms/javascript/enriching-events/context/
   */
  captureException(err, captureContext = null) {
    if (useSentry) {
      Sentry.captureException(err, captureContext);
    } else {
      console.log(err);
    }
  }
};
