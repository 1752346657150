import axios from 'axios';
import qs from 'qs';

class VideoSearchService {
  constructor() {
    this.apiClient = axios.create({
      baseURL: '/api/v1/video'
    });

    this.genericSearchApiClient = axios.create({
      baseURL: '/api/v1/search'
    });
  }

  parseUrlParams() {
    const params = qs.parse(location.search, { ignoreQueryPrefix: true, comma: true });

    // Ensure that the page and century params are integer
    ['p', 'century'].forEach((paramKey) => {
      if (params.hasOwnProperty(paramKey)) {
        if (Array.isArray(params[paramKey])) {
          params[paramKey] = params[paramKey].map((val) => parseInt(val));
        } else params[paramKey] = parseInt(params[paramKey]);
      }
    });

    return params;
  }

  async search(params, size = 9) {
    const urlParams = qs.stringify(params, { arrayFormat: 'comma', encode: false });

    let newUrl;

    // Clear current url if there are no params at all
    if (!urlParams) {
      newUrl = location.href.replace(location.search, '');
    } else {
      newUrl = location.search
        ? location.href.replace(location.search, `?${urlParams}`)
        : `${location.href}?${urlParams}`;
    }

    history.pushState({}, null, newUrl);

    return (await this.genericSearchApiClient
      .post(
        `/?${urlParams}`,
        {
          size
        }
      )
    ).data;
  }

  async loadMore(size, from) {
    return (await this.genericSearchApiClient
      .post(
        `/${location.search}`, {
          size,
          from
        }
      )).data;
  }

  async loadFilterOptions() {
    return (await this.genericSearchApiClient.get('/filter')).data;
  }

  async loadPastLivestreams(size, from = null) {
    return (await this.apiClient
      .post(
        '/search?video_type=stream_p',
        {
          size,
          from
        }
      )).data;
  }
}

const videoSearchService = new VideoSearchService();

export { videoSearchService };
