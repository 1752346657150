<template>
  <div class="t1-embeddedVideo video-container">
    <iframe
      :src="src"
      width="640"
      height="360"
      frameborder="0"
      webkitallowfullscreen
      mozallowfullscreen
      allowfullscreen />
  </div>
</template>

<script>
export default {
  props: {
    src: {
      type: String,
      required: true,
    }
  }
};
</script>
