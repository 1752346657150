import * as types from './mutation-types';

export default {
  [types.SET_URL_PARAMS](state, params) {
    state.urlParams = params;
  },
  [types.SET_RESULTS](state, results) {
    state.results = results;
  },
  [types.ADD_RESULTS](state, results) {
    state.results.hits = state.results.hits.concat(results);
  },
  [types.SET_LOADING_STATE](state, isLoading) {
    state.isLoading = isLoading;
  },
  [types.SET_FILTER_CONFIG](state, config) {
    state.filterConfig = config;
  },
  [types.SET_FILTER_OPTIONS](state, filterOptions) {
    state.filterOptions = filterOptions;
  },
  [types.SET_PAGE_SIZE](state, pageSize) {
    state.pageSize = pageSize;
  }
};
