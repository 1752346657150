<template>
  <div class="t1-videoMeta">
    <ul class="t1-videoMeta-list">
      <meta-row
        v-for="row in metaRows"
        :key="row.key"
        :icon-class="row.iconClass"
        :tooltip-content="row.tooltipContent">
        <span
          class="flex flex-wrap gap-x-2"
          v-if="artistPagesEnabled && Array.isArray(row.value)">
          <span
            v-for="(item, index) in row.value"
            :key="item.id"
            class="flex">
            <a
              class="transition-colors hover:underline hover:text-primary"
              v-if="item.artistPage && item.artistPage.visible"
              href=""
              @click.prevent="showArtistPage(item)">
              {{ item.title }}<template v-if="(index + 1) < row.value.length">, </template>
            </a>

            <template v-else>
              {{ item.title }}<template v-if="(index + 1) < row.value.length">, </template>
            </template>
          </span>
        </span>

        <template v-else>
          {{ row.value }}
        </template>
      </meta-row>
    </ul>

    <ArtistPageModal
      v-if="artistPagesEnabled && activeArtistPage"
      @close="activeArtistPage = null"
      :artist-page="activeArtistPage" />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import videoMixin from 'mixins/video';
import MetaRow from './MetaRow.vue';
import ArtistPageModal from './ArtistPageModal.vue';

export default {
  mixins: [videoMixin],
  props: {
    itemWhitelist: {
      type: Array,
      required: false,
      default: null
    },
    itemBlacklist: {
      type: Array,
      required: false,
      default: null
    },
    video: {
      type: Object,
      required: true
    },
    withArtistPageLink: {
      type: Boolean,
      required: false,
      default: false,
    }
  },
  data() {
    return {
      activeArtistPage: null,
    };
  },
  components: { MetaRow, ArtistPageModal },
  computed: {
    ...mapState('generic', ['theme']),
    artistPagesEnabled() {
      return this.withArtistPageLink && this.theme.videos?.artistPagesEnabled;
    },
    duration() {
      if (this.isLiveStream && !this.inVoDMode) {
        return null;
      }

      return this.$store.getters.duration;
    },
    persons() {
      return this.video.persons ? this.video.persons : [];
    },
    composers() {
      return this.persons.filter((p) => p.type === 'Composer');
    },
    composersList() {
      if (this.artistPagesEnabled) {
        return this.composers.map((p) => ({
          title: p.fullName,
          artistPageTitle: p.fullName,
          ...p
        }));
      }
      return this.composers.map((p) => p.fullName).join(', ');
    },
    conductor() {
      return this.persons.find((p) => p.type === 'Conductor');
    },
    conductorsList() {
      if (this.conductor) {
        if (this.artistPagesEnabled) {
          return [this.conductor].map((p) => ({
            title: p.fullName,
            artistPageTitle: p.fullName,
            ...p
          }));
        }

        return this.conductor.fullName;
      }

      return null;
    },
    soloists() {
      return this.persons.filter((p) => p.type === 'Soloist');
    },
    soloistsList() {
      const list = [];

      this.soloists.forEach((p) => {
        let row = p.fullName;

        if (p.instrument) {
          row = `${row} (${p.instrument})`;
        }

        if (this.artistPagesEnabled) {
          list.push({
            title: row,
            artistPageTitle: p.fullName,
            ...p,
          });
        } else {
          list.push(row);
        }
      });

      return this.artistPagesEnabled ? list : list.join(', ');
    },
    ensembles() {
      return this.video.ensembles ? this.video.ensembles : [];
    },
    choirs() {
      return this.video.choirs ? this.video.choirs : [];
    },
    groups() {
      return this.ensembles.concat(this.choirs);
    },
    groupsList() {
      if (this.artistPagesEnabled) {
        return this.groups.map((g) => ({
          title: g.name,
          artistPageTitle: g.name,
          ...g
        }));
      }
      return this.groups.map((g) => g.name).join(', ');
    },
    location() {
      return this.video.location;
    },
    dateOfPerformance() {
      if (this.isLiveStream) {
        return this.startDate ? this.startDate.toLocal().toFormat('dd.MM.yyyy, HH:mm') : undefined;
      }

      return this.video.dateOfPerformance;
    },
    leadingTeam() {
      return this.video.leadingTeam;
    },
    metaRows() {
      const list = [
        {
          key: 'composersList',
          iconClass: 'icon-takt1_accordion_section_audio',
          tooltipContent: this.$t('video.meta.composers')
        },
        {
          key: 'groupsList',
          iconClass: 'icon-takt1_ensemble',
          tooltipContent: this.$t('video.meta.groups')
        },
        {
          key: 'conductorsList',
          iconClass: 'icon-takt1_conductor',
          tooltipContent: this.$t('video.meta.conductor')
        },
        {
          key: 'leadingTeam',
          iconClass: 'icon-takt1_live',
          tooltipContent: this.$t('video.meta.leadingTeam')
        },
        {
          key: 'soloistsList',
          iconClass: 'icon-takt1_soloist',
          tooltipContent: this.$t('video.meta.soloists')
        },
        {
          key: 'location',
          iconClass: 'icon-takt1_location',
          tooltipContent: this.$t('video.meta.location')
        },
        {
          key: 'dateOfPerformance',
          iconClass: 'icon-takt1_cancel',
          tooltipContent: this.$t('video.meta.dateOfPerformance')
        },
        {
          key: 'duration',
          iconClass: 'icon-takt1_date',
          tooltipContent: this.$t('video.meta.duration')
        },
      ];

      list.forEach((row, index) => {
        if (this.itemWhitelist && Array.isArray(this.itemWhitelist) && !this.itemWhitelist.includes(row.key)) {
          delete row[index];
          return;
        }

        if (this.itemBlacklist && Array.isArray(this.itemBlacklist) && this.itemBlacklist.includes(row.key)) {
          delete row[index];
          return;
        }

        const value = this[row.key];
        if (value && value !== '') {
          row.value = this[row.key];
        }
      });

      return list.filter((row) => {
        if (!row.value) {
          return false;
        }

        if (typeof row.value === 'string' && row.value === '') {
          return false;
        }

        if (Array.isArray(row.value) && row.value.length === 0) {
          return false;
        }

        return true;
      });
    }
  },
  methods: {
    showArtistPage(row) {
      this.activeArtistPage = row;
    }
  }
};
</script>
