<template>
  <div
    class="t1-locale"
    :class="{ 't1-locale--active': locale.isActive }">
    <a
      :href="url">
      {{ locale.short }}
    </a>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  props: {
    locale: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapState('generic', ['locales']),
    activeLocale() {
      return this.locales.find((locale) => locale.isActive);
    },
    url() {
      let { pathname } = location;

      const activeLocaleBase = this.activeLocale.baseUrl.slice(0, -1);
      const localeBaseUrl = this.locale.baseUrl.slice(0, -1);

      if (pathname.indexOf(activeLocaleBase) === 0) {
        pathname = pathname.replace(activeLocaleBase, localeBaseUrl);
      } else {
        pathname = `${this.locale.baseUrl}${pathname.substr(1)}`;
      }

      return pathname;
    }
  }
};
</script>

<style lang="css">
.t1-locale {
  &:not(.t1-locale--active):hover {
    text-decoration: underline;
  }

  &.t1-locale--active {
    cursor: default;
    pointer-events: none;
    text-decoration: underline;
  }
}
</style>
