import { cloneObject } from 'utils';
import { videoSearchService } from 'services/VideoSearchService';
import notification from 'utils/noty';
import { i18n } from 'utils/i18n';
import * as types from './mutation-types';

export default {
  async init({ commit }, payload) {
    if (payload?.filterConfig) {
      commit(types.SET_FILTER_CONFIG, payload.filterConfig);
    }

    try {
      const filterOptions = await videoSearchService.loadFilterOptions();
      commit(types.SET_FILTER_OPTIONS, filterOptions);
    } catch (e) {
      notification.error(i18n.t('generic.messages.videoLoadFailed'));
    }
    commit(types.SET_PAGE_SIZE, payload.pageSize);
  },
  async search({
    state, getters, commit, dispatch
  }) {
    dispatch('setLoadingState', true);
    try {
      const results = await videoSearchService.search(getters.urlParams, state.pageSize);
      commit(types.SET_RESULTS, results);
    } catch (e) {
      notification.error(i18n.t('generic.messages.videoLoadFailed'));
    }
    dispatch('setLoadingState', false);
  },
  async loadMore({
    state, getters, commit, dispatch
  }) {
    dispatch('setLoadingState', true);
    try {
      const results = await videoSearchService.loadMore(state.pageSize, getters.videos?.length);
      commit(types.ADD_RESULTS, results.hits);
    } catch (e) {
      notification.error(i18n.t('generic.messages.videoLoadFailed'));
    }
    dispatch('setLoadingState', false);
  },
  setUrlParams({ commit, getters }, params) {
    // Reset page if any other filter has changed
    if (getters.urlParams.p && getters.urlParams.p === params.p) params.p = 1;

    commit(types.SET_URL_PARAMS, params);
  },
  setResults({ commit }, results) {
    commit(types.SET_RESULTS, results);
  },
  setUrlParam({ dispatch, getters }, { key, value }) {
    const urlParams = cloneObject(getters.urlParams);

    if (value) urlParams[key] = value;
    else delete urlParams[key];

    dispatch('setUrlParams', urlParams);
  },
  resetAllFilters({ dispatch }) {
    dispatch('setUrlParams', {});
  },
  setLoadingState({ commit }, isLoading) {
    commit(types.SET_LOADING_STATE, isLoading);
  },
};
