const cloneObject = (obj) => JSON.parse(JSON.stringify(obj));

/**
 * Check if the passed click event should close an open element.
 *
 * Default is true, false only if the passed notCloseForClass is in the event target path.
 *
 * @param {MouseEvent} e
 * @param {string} notCloseForClass
 * @return {boolean}
 */
const shouldCloseOnClick = (e, notCloseForClass) => {
  let el = e.target;

  if (!el) return true;

  while (el.nodeName !== 'BODY') {
    if (el.classList && el.classList.contains(notCloseForClass)) {
      return false;
    }

    el = el.parentElement;

    // Break if the parentElement is null, this can happen if the the click was inside an
    // element which is hidden right after the click
    if (!el) return true;
  }

  return true;
};

const arrayCompareFunction = (key, order = 'asc') => (a, b) => {
  let varA = key.split('.').reduce((o, i) => o[i], a);
  if (typeof varA === 'string') varA = varA.toUpperCase();

  let varB = key.split('.').reduce((o, i) => o[i], b);
  if (typeof varB === 'string') varB = varB.toUpperCase();

  if (!varA && !varB) return 0;

  let comparison = 0;

  if (!varA) comparison = -1;
  if (!varB) comparison = 1;

  if (varA > varB) comparison = 1;
  else if (varA < varB) comparison = -1;

  return order === 'desc' ? comparison * -1 : comparison;
};

const sortArray = (array, key, order = 'asc') => array.sort(arrayCompareFunction(key, order));

const timeout = async (ms) => new Promise((resolve) => {
  setTimeout(() => {
    resolve();
  }, ms);
});

const handleFixedHeaderSpacing = () => {
  const header = document.getElementById('t1-mainHeader');
  const logoOverlayEnabled = header.classList.contains('t1-mainHeader--logoOverlayEnabled');
  const main = document.getElementsByTagName('main')[0];
  const logo = document.getElementById('t1-mainLogo');

  // Retry after a short timeout if one of the required elements is not available yet (rendered inside another component)
  if (!header || !main) {
    setTimeout(() => {
      handleFixedHeaderSpacing();
    }, 10);
    return;
  }

  const adjustOffsetForHeader = () => {
    let safeAreaTop = 0;
    if (logoOverlayEnabled && logo) {
      safeAreaTop += Math.max(logo.clientHeight, header.clientHeight);
    } else {
      safeAreaTop += header.clientHeight;
    }

    main.style.paddingTop = `${safeAreaTop}px`;
  };

  const headerResizeObserver = new ResizeObserver(() => {
    adjustOffsetForHeader();
  });

  headerResizeObserver.observe(header);
};

// Calculate the scrollbar width and update the css var
// Basically taken from https://stackoverflow.com/a/66726233
const handleScrollbarWidth = () => {
  const calcScrollbarWidth = () => {
    const scrollbarWidth = window.innerWidth - document.documentElement.clientWidth;
    document.documentElement.style.setProperty('--scrollbar-width', `${scrollbarWidth}px`);
  };

  window.addEventListener('resize', calcScrollbarWidth, false);
  window.addEventListener('DOMContentLoaded', calcScrollbarWidth, false);
  window.addEventListener('load', calcScrollbarWidth);
};

export {
  cloneObject, shouldCloseOnClick, sortArray, timeout, handleFixedHeaderSpacing, handleScrollbarWidth
};
