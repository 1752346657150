<template>
  <div class="video-container t1-threeqVideoPlayer">
    <div
      ref="player"
      :id="playerId" />
  </div>
</template>

<script>
import logger from 'utils/logger';
import { Severity } from '@sentry/browser';
import VideoPlayer from 'utils/video-player';
import scrollMonitor from 'scrollmonitor';
import { mapState } from 'vuex';

export default {
  props: {
    file: {
      type: Object,
      required: true
    },
    idPrefix: {
      type: String,
      required: false,
      default: null,
    },
    customOptions: {
      type: Object,
      required: false,
      default: null,
    },
    fillParentContainer: {
      type: Boolean,
      required: false,
      default: false,
    }
  },
  data() {
    return {
      player: null,
      watcher: null,
    };
  },
  mounted() {
    this.init();

    this.$nextTick(() => {
      this.adjustToFillParent();
    });
  },
  computed: {
    ...mapState('generic', ['documentWidth']),
    playerId() {
      let playerId = this.file.id;

      if (this.idPrefix) {
        playerId = `${this.idPrefix}-${playerId}`;
      }

      return playerId;
    },
    options() {
      let options = {
        dataid: this.file.playoutId,
        container: this.playerId,
        // TODO do we have protected files?
        // key: this.playerConfig.token,
        // timestamp: this.playerConfig.timestamp,
        autoplay: false,
        allowmutedautoplay: true,
      };

      if (this.fillParentContainer) {
        options.layout = 'default';
      }

      if (this.customOptions) {
        options = {
          ...options,
          ...this.customOptions
        };
      }

      return options;
    },
    isInCarousel() {
      return this.$parent?.$options?._componentTag === 'slide';
    },
    isActiveCarouselSlide() {
      if (this.isInCarousel) {
        return this.$parent.isActive;
      }

      return false;
    }
  },
  watch: {
    isActiveCarouselSlide() {
      if (this.isInCarousel && this.player) {
        if (this.player.playing && !this.isActiveCarouselSlide) {
          this.player.pause();
        }

        if (this.isActiveCarouselSlide && this.options.autoplay && !this.player.playing) {
          this.player.play();
        }
      }
    },
    documentWidth() {
      this.adjustToFillParent();
    }
  },
  methods: {
    async loadScript() {
      try {
        await this.$loadScript('https://player.3qsdn.com/js3q.latest.js');
      } catch (e) {
        this.$notification.error(this.$t('video.player.scriptLoadingFailed'));

        logger.captureException(new Error('js3q player script load failed'), { level: Severity.Warning });
        await Promise.reject();
      }
    },
    async init() {
      try {
        await this.loadScript();
        await this.initPlayer();
        this.addWatcher();
      } catch (e) {
        // noop, user informed and logged within the load script function
      }
    },
    async initPlayer() {
      const player = new VideoPlayer();
      await player.init(this.options);

      this.player = player;
    },
    addWatcher() {
      this.watcher = scrollMonitor.create(this.$el);

      // pause the video if it exits the viewport
      this.watcher.exitViewport(() => {
        if (this.player && this.player.playing) {
          this.player.pause();
        }
      });

      // watch for viewport enter if autoplay is enabled
      if (this.options.autoplay) {
        this.watcher.fullyEnterViewport(() => {
          // break if the parent component is an inactive carousel slide
          if (this.isInCarousel && !this.$parent.isActive) {
            return;
          }

          if (this.player && !this.player.playing) {
            this.player.play();
          }
        });
      }
    },
    adjustToFillParent() {
      // Fill up the parent container while keeping the 16/9 aspect ratio of the video itself (so parts will not be visible)
      if (this.fillParentContainer) {
        const containerRect = this.$el.getBoundingClientRect();
        const requiredWidth = (containerRect.height / 9) * 16;
        const requiredHeight = (containerRect.width / 16) * 9;

        if (requiredWidth > containerRect.width) {
          this.$refs.player.style.width = `${requiredWidth}px`;
          this.$refs.player.style.left = `-${(requiredWidth - containerRect.width) / 2}px`;
        } else {
          this.$refs.player.style.width = '100%';
          this.$refs.player.style.left = '0px';
        }

        if (requiredHeight > containerRect.height) {
          this.$refs.player.style.height = `${requiredHeight}px`;
          this.$refs.player.style.top = `-${(requiredHeight - containerRect.height) / 2}px`;
        } else {
          this.$refs.player.style.height = '100%';
          this.$refs.player.style.top = '0px';
        }
      }
    }
  }
};
</script>

<style lang="css">
.t1-threeqVideoPlayer {
  > div {
    @apply absolute top-0 left-0 w-full h-full z-10;
  }
}
</style>
