<template>
  <div
    class="t1-gok-discoverMenu"
    :class="{ 't1-gok-discoverMenu--mobile': isMobile }">
    <div class="t1-gok-discoverMenu-headline text-center uppercase mt-8 mb-20">
      <a
        v-if="isMobile"
        href=""
        @click.prevent="$emit('level-back')">
        <i class="fas fa-chevron-left" />
      </a>
      {{ $t('guerzenich.menuTitle') }}
    </div>

    <div class="flex flex-wrap lg:-mx-8 -mt-16">
      <nav
        v-for="section in menu"
        :key="section.title">
        <div>
          {{ section.title }}
        </div>

        <ul>
          <li
            v-for="entry in section.entries"
            :key="`${entry.href}_${entry.title}`">
            <a
              :href="entry.href"
              :target="entry.target">
              {{ entry.title }}
            </a>
          </li>
        </ul>
      </nav>
    </div>
  </div>
</template>

<script>
import { cloneObject } from 'utils';

export default {
  props: {
    isMobile: {
      type: Boolean,
      required: false,
      default: false,
    }
  },
  data() {
    return {
      menu: cloneObject(payload.generic.menu),
    };
  }
};
</script>

<style lang="css">
.t1-gok-discoverMenu {
  .t1-gok-discoverMenu-headline {
    @apply text-7xl font-light mt-8 mb-20;
  }

  &.t1-gok-discoverMenu--mobile {
    .t1-gok-discoverMenu-headline {
      @apply text-4xl mt-0 mb-12 text-left flex items-center;

      a {
        @apply flex items-center;
      }

      svg {
        @apply text-4xl mr-4;
      }
    }
  }

  nav {
    @apply w-full lg:w-1/2 lg:px-8 mt-16;

    > div {
      @apply text-4xl mb-4 uppercase font-light;;
    }

    ul {
      li {
        @apply border-t-2 border-mono-0 py-2 text-2xl;

        &:hover {
          a {
            box-shadow: 0 2px 0 0 #000;
          }
        }
      }
    }
  }

  @media screen and (max-width: calc(theme('screens.lg') - 1px)) {
    @apply absolute top-0 right-0 w-full h-full bg-mono-100 transition transition-transform;
    transform: translateX(100%);

    .t1-gok-mainMenu--level-2 & {
      transform: translateX(0);
    }
  }
}
</style>
