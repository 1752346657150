<template>
  <div class="t1-gok-mainMenu">
    <a
      @click.prevent="toggle"
      class="t1-mobileNav-toggle">
      <i class="fas fa-bars" />
      <span>{{ $t('guerzenich.menuTitle') }}</span>
    </a>

    <div
      class="t1-gok-mainMenu-content"
      :class="{'t1-gok-mainMenu-content--visible': isVisible}">
      <div
        @click.prevent="hide"
        class="t1-gok-mainMenu-overlay" />

      <div class="t1-gok-mainMenu-inner">
        <div class="flex justify-between items-center relative -top-6">
          <locale-control
            v-if="isMobile && level === 1" />
          <div
            v-if="!isMobile || level === 1"
            class="t1-gok-closeCta ml-auto">
            <a
              href=""
              @click.prevent="hide">
              <i class="fas fa-times" />
            </a>
          </div>
        </div>

        <div
          class="t1-gok-scrollContent"
          :class="[`t1-gok-mainMenu--level-${level}`]">
          <template v-if="isMobile">
            <div>
              <nav class="t1-gok-level1-primary">
                <ul>
                  <li>
                    <a
                      :href="$t('guerzenich.menu.level1.primary.program.link')">
                      {{ $t('guerzenich.menu.level1.primary.program.title') }}
                    </a>
                  </li>
                  <li>
                    <a
                      :href="$t('guerzenich.menu.level1.primary.abo.link')">
                      {{ $t('guerzenich.menu.level1.primary.abo.title') }}
                    </a>
                  </li>
                  <li>
                    <a
                      :href="$t('guerzenich.menu.level1.primary.visit.link')">
                      {{ $t('guerzenich.menu.level1.primary.visit.title') }}
                    </a>
                  </li>
                  <slot name="primary-nav-links" />
                  <li>
                    <a
                      href=""
                      @click.prevent="level = 2">
                      {{ $t('guerzenich.menuTitle') }}
                    </a>
                  </li>
                </ul>
              </nav>

              <nav class="t1-gok-level1-secondary">
                <ul>
                  <li>
                    <a
                      :href="`https://www.guerzenich-orchester.de/${activeLocale.short}/search/event/`">
                      <i class="fas fa-search mr-1" />
                      {{ $t('guerzenich.menu.level1.secondary.search') }}
                    </a>
                  </li>
                  <li>
                    <a
                      :href="`https://shop.guerzenich-orchester.de/webshop/webticket/customerlogin.secure?language=${activeLocale.short}`">
                      <i class="fas fa-user mr-1" />
                      {{ $t('guerzenich.menu.level1.secondary.login') }}
                    </a>
                  </li>
                  <li>
                    <a
                      :href="`https://shop.guerzenich-orchester.de/webshop/webticket/shoppingcart?language=${activeLocale.short}`">
                      <i class="fas fa-shopping-cart mr-1" />
                      {{ $t('guerzenich.menu.level1.secondary.cart') }}
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
          </template>

          <guerzenich-discover-menu
            :is-mobile="isMobile"
            @level-back="level = 1" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import GuerzenichDiscoverMenu from './GuerzenichDiscoverMenu.vue';

export default {
  data() {
    return {
      isVisible: false,
      level: 1,
    };
  },
  components: {
    GuerzenichDiscoverMenu,
  },
  computed: {
    ...mapState('generic', ['documentWidth']),
    ...mapGetters('generic', ['activeLocale']),
    isMobile() {
      return this.documentWidth < 1280;
    }
  },
  methods: {
    show() {
      this.isVisible = true;
    },
    hide() {
      this.isVisible = false;
    },
    toggle() {
      if (this.isVisible) {
        this.hide();
      } else {
        this.show();
      }
    }
  }
};
</script>

<style lang="css">
.t1-gok-mainMenu {
  .t1-mobileNav-toggle {
    @apply cursor-pointer;
    font-size: 1.6875rem;

    svg {
      @apply mr-2;
    }

    > span {
      @media screen and (max-width: calc(theme('screens.lg') - 1px)) {
        @apply hidden;
      }
    }

    &:hover span {
      box-shadow: 0 2px 0 0 #000;
    }
  }

  .t1-localeControl {
    @apply text-lg uppercase;

    .t1-locale--active {
      @apply font-bold no-underline;
    }
  }

  .t1-gok-mainMenu-content {
    .t1-gok-mainMenu-inner {
      @apply fixed bg-mono-100 transition-transform p-10 top-0 right-0 h-full flex flex-col;
      transform: translateX(100%);
      width: 768px;
      max-width: 100%;
      z-index: 20020;

      .t1-gok-scrollContent {
        @apply relative flex-auto overflow-y-auto overflow-x-hidden;
      }

      .t1-gok-closeCta {
        @apply flex-none text-right relative -right-6 text-3xl;
      }
    }

    &.t1-gok-mainMenu-content--visible {
      .t1-gok-mainMenu-overlay {
        @apply fixed top-0 left-0 w-full h-full;
        background: rgba(0, 0, 0, 0.75);
        z-index: 20010;
      }

      .t1-gok-mainMenu-inner {
        transform: translateX(0);
      }
    }
  }

  .t1-gok-level1-primary, .t1-gok-level1-secondary {
    li {
      @apply py-2;

      &:not(:last-child) {
        @apply border-b-2 border-mono-0;
      }
    }
  }

  .t1-gok-level1-primary {
    @apply text-4xl mt-16 uppercase;
  }

  .t1-gok-level1-secondary {
    @apply text-xl mt-16;
  }
}
</style>
