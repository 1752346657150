import Vue from 'vue';
import VueI18n from 'vue-i18n';
import en from 'src/lang/en.json';
import de from 'src/lang/de.json';
import es from 'src/lang/es.json';

Vue.use(VueI18n);

const locale = payload.generic.locale.short;

const i18n = new VueI18n({
  locale: 'en',
  fallbackLocale: 'en',
  messages: { en }
});

const messages = {
  en,
  de,
  es,
};

const loadLocaleFile = async () => {
  i18n.setLocaleMessage(locale, messages[locale]);
  i18n.locale = locale;
};

loadLocaleFile();

export {
  i18n, loadLocaleFile
};
