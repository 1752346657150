import Vue from 'vue';
import Vuex from 'vuex';
import { DateTime } from 'luxon';
import * as member from '../modules/member';
import * as generic from '../modules/generic';
import * as videoSearch from '../modules/video-search';
import * as cm from '../modules/cm';

Vue.use(Vuex);

const state = {
  currentDateTime: DateTime.utc()
};

// Update currentDateTime value each second
setInterval(() => {
  state.currentDateTime = DateTime.utc();
}, 1000);

const store = new Vuex.Store({
  modules: {
    member,
    generic,
    videoSearch,
    cm
  },
  state
});

cm.init(store);

export default store;
