<template>
  <div
    class="t1-audioRecordCard t1-mediaLibraryCard relative border border-mono-90 flex-grow"
    @mouseenter="hovered = true"
    @mouseleave="hovered = false">
    <div
      class="lazyload t1-mediaLibraryCard-image"
      :data-bg="image"
      :title="imageCredits"
      :class="{'t1-mediaLibraryCard--noThumbnail': !image}"
      :style="{ paddingBottom: imageRatio }">
      <i
        class="fa fa-podcast"
        v-if="!image" />

      <a :href="record.link" />
    </div>

    <a
      class="t1-mediaLibraryCard-overlay"
      :href="record.link">
      <div class="t1-mediaLibraryCard-overlay-base">
        <div class="font-bold pr-4">
          <a>
            {{ cardTitle }}
          </a>
        </div>

        <div class="t1-mediaLibraryCard-overlay-cta bg-primary text-primary-contrast">
          <i class="icon-takt1_arrow" />
        </div>
      </div>
    </a>
  </div>
</template>

<script>
export default {
  props: {
    record: {
      type: Object,
      required: true,
    },
    imageRatio: {
      type: String,
      required: false,
      default: '56.25%'
    }
  },
  data() {
    return {
      hovered: false,
    };
  },
  computed: {
    imageObject() {
      if (this.record.previewImage && this.record.previewImage.url) {
        return this.record.previewImage;
      }

      return null;
    },
    image() {
      if (this.imageObject) {
        return this.imageObject.square?.medium ?? this.imageObject.url;
      }

      return null;
    },
    imageCredits() {
      if (this.imageObject && this.imageObject.credits) {
        return this.imageObject.credits;
      }

      return null;
    },
    cardTitle() {
      return this.record.title;
    }
  }
};
</script>
