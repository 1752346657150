import Noty from 'noty';
import 'styles/includes/noty.css';

class Notification {
  constructor() {
    this.defaultOptions = {
      // relax, mint, metroui
      theme: 'mint',
      // top, topLeft, topCenter, topRight, center, centerLeft, centerRight, bottom, bottomLeft, bottomCenter, bottomRight
      layout: 'bottomCenter',
      timeout: 3000,
      progressBar: true,
      animation: {
        speed: 250
      }
    };
  }

  static addIconToText(text, iconName, prefix = 'fas') {
    return `<span class="t1-notyIcon"><i class="${prefix} ${iconName}"></i></span> ${text}`;
  }

  show(text, type, options = {}, triggerShow = true) {
    const noty = new Noty({
      text,
      type,
      ...this.defaultOptions,
      ...options
    });

    if (triggerShow) noty.show();

    return noty;
  }

  info(text, options = {}, triggerShow = true) {
    return this.show(
      Notification.addIconToText(text, 'fa-exclamation'),
      'info',
      options,
      triggerShow
    );
  }

  success(text, options = {}, triggerShow = true) {
    return this.show(
      Notification.addIconToText(text, 'fa-check'),
      'success',
      options,
      triggerShow
    );
  }

  warning(text, options = {}, triggerShow = true) {
    return this.show(
      Notification.addIconToText(text, 'fa-exclamation-triangle'),
      'warning',
      options,
      triggerShow
    );
  }

  error(text, options = {}, triggerShow = true) {
    return this.show(
      Notification.addIconToText(text, 'fa-exclamation-triangle'),
      'error',
      {
        ...this.defaultOptions,
        timeout: false,
        ...options
      },
      triggerShow
    );
  }

  showByType(text, type, options = {}, triggerShow = true) {
    switch (type) {
      case 'success':
        return this.success(text, options, triggerShow);
      case 'warning':
        return this.warning(text, options, triggerShow);
      case 'error':
        return this.error(text, options, triggerShow);
      default:
        return this.info(text, options, triggerShow);
    }
  }
}

export default new Notification();
