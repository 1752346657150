<template>
  <div
    class="t1-videoCard t1-mediaLibraryCard relative border border-mono-90 flex-grow"
    :class="{'t1-mediaLibraryCard--locked': !video.canAccess}"
    @mouseenter="hovered = true"
    @mouseleave="hovered = false">
    <div
      class="lazyload t1-mediaLibraryCard-image"
      :data-bg="previewImage"
      :title="previewImageCredits"
      :class="{'t1-mediaLibraryCard--noThumbnail': !previewImage}"
      :style="{ paddingBottom: imageRatio }">
      <i
        class="fa fa-video"
        v-if="!previewImage" />

      <a :href="video.link" />
    </div>

    <a
      class="t1-mediaLibraryCard-overlay"
      :href="video.link">
      <div class="t1-mediaLibraryCard-overlay-base">
        <div class="font-bold pr-4">
          <a>
            {{ cardTitle }}
          </a>
        </div>

        <div class="t1-mediaLibraryCard-overlay-cta bg-primary text-primary-contrast">
          <i class="icon-takt1_arrow" />
        </div>
      </div>

      <template v-if="showDetails">
        <transition
          :css="false"
          @enter="enterAnimation"
          @leave="leaveAnimation">
          <VideoMeta
            class="t1-mediaLibraryCard-overlay-details text-xs"
            v-if="hovered"
            :video="video"
            :item-whitelist="theme.videos.videoHoverDetails"
          />
        </transition>
      </template>
    </a>

    <a
      href=""
      v-if="!video.canAccess"
      @click.prevent="lockedVideoClickHandler"
      class="t1-mediaLibraryCard-lockedOverlay bg-mono-0 text-mono-100">
      <i class="fas fa-lock" />
    </a>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import videoMixin from 'mixins/video';
import slideAnimation from 'mixins/slideAnimation';
import VideoMeta from 'components/generic/VideoMeta.vue';

export default {
  props: {
    video: {
      type: Object,
      required: true
    },
    imageRatio: {
      type: String,
      required: false,
      default: '56.25%'
    }
  },
  data() {
    return {
      hovered: false,
    };
  },
  components: { VideoMeta },
  mixins: [videoMixin, slideAnimation],
  computed: {
    ...mapState('generic', ['theme']),
    showDetails() {
      return this.theme?.videos?.showDetailsOnHover
        && this.theme?.videos?.videoHoverDetails
        && Array.isArray(this.theme.videos.videoHoverDetails)
        && this.theme.videos.videoHoverDetails.length > 0;
    }
  },
  methods: {
    ...mapActions('generic', ['showRestrictionModal']),
    lockedVideoClickHandler() {
      this.showRestrictionModal(this.video);
    }
  }
};
</script>
