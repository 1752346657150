import { cloneObject } from 'utils';

export default {
  results(state) {
    return state.results;
  },
  totalResults(state, getters) {
    return getters?.results?.total;
  },
  videos(state, getters) {
    return getters?.results?.hits;
  },
  aggregations(state, getters) {
    return getters?.results?.aggregations;
  },
  urlParams(state, getters, rootState) {
    const urlParams = cloneObject(state.urlParams);

    Object.keys(urlParams).forEach((key) => {
      if (urlParams[key] === '' || urlParams[key] === 0) {
        delete urlParams[key];
      }
    });

    if (rootState.generic?.theme?.videos?.showUpcomingStreamsInLibrary === false) {
      urlParams.video_type = 'stream_p,vod';
    } else if (urlParams.video_type) {
      delete urlParams.video_type;
    }

    return urlParams;
  },
  canLoadMore(state, getters) {
    if (!getters.videos) return false;

    return getters.videos.length < getters.totalResults;
  },
};
