import { videoSearchService } from 'services/VideoSearchService';
import actions from './actions';
import mutations from './mutations';
import getters from './getters';

export const namespaced = true;

const state = {
  urlParams: videoSearchService.parseUrlParams(),
  filterConfig: [],
  filterOptions: [],
  results: [],
  isLoading: false,
  pageSize: 9
};

export {
  getters, actions, mutations, state
};
