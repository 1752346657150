import * as types from './mutation-types';

export default {
  [types.ADD_TO_OVERLAY_STACK](state, uid) {
    state.overlayStack.push(uid);
  },
  [types.REMOVE_FROM_OVERLAY_STACK](state, uid) {
    const index = state.overlayStack.findIndex((id) => id === uid);

    if (index > -1) {
      state.overlayStack.splice(index, 1);
    }
  },
  [types.SET_SELECTED_VIDEO_PAGE](state, video) {
    state.selectedVideo = video;
  },
  [types.SET_ACCESS_CODE_MODAL_VISIBILITY](state, visible) {
    state.accessCodeModalVisible = visible;
  },
  [types.SET_ACCESS_CODE_MODAL_MESSAGE](state, message) {
    state.accessCodeModalMessage = message;
  },
  [types.SET_ACTIVE_DROPDOWN_FILTER](state, filter) {
    state.activeDropDownFilter = filter;
  },
  [types.SET_MOBILE_MENU_VISIBILITY](state, isVisible) {
    state.mobileMenuVisible = isVisible;
  },
};
