import { cloneObject } from 'utils';
import actions from './actions';
import mutations from './mutations';
import getters from './getters';

export const namespaced = true;

const state = {
  member: payload.member ? cloneObject(payload.member) : null,
  loginVisible: payload.generic.loginVisible ? payload.generic.loginVisible : false,
  loginMessage: null
};

try {
  let loginMessage = sessionStorage.getItem('t1-loginMessage');
  if (loginMessage) {
    loginMessage = JSON.parse(loginMessage);
    sessionStorage.removeItem('t1-loginMessage');
    state.loginVisible = true;
    state.loginMessage = loginMessage;
  }
} catch (e) {
  // noop
}

export {
  getters, actions, mutations, state
};
