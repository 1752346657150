<template>
  <div class="t1-siteSearch">
    <a
      href="#"
      ref="toggleCta"
      @click.prevent="toggle"
      class="hover:underline">
      <img class="icon-search" src="/_resources/themes/v1/img/guerzenich/icon-search-white.svg">
      {{ $t('siteSearch.cta') }}
    </a>
    <div
      v-show="isVisible"
      class="t1-searchField">
      <vue-simple-suggest
        v-model="term"
        :list="suggest"
        :debounce="400"
        @select="selected"
        ref="suggestField"
        :prevent-submit="false"
        :prevent-hide="true"
        @blur="handleBlur">
        <div class="t1-searchField-inputWrapper flex items-center">
          <input
            :placeholder="$t('siteSearch.placeholder')"
            type="search"
            name="term"
            :value="term"
            autocomplete="off"
            autocorrect="off"
            autocapitalize="off"
            spellcheck="false"
            @keydown.enter="enter">

          <div class="t1-searchField-cta">
            <a
              ref="searchCta"
              href=""
              @click.prevent="enter">
              <i class="fas fa-search" />
            </a>
          </div>
        </div>

        <template
          #suggestion-item="{ suggestion }">
          <div v-html="suggestion.html" />
        </template>
      </vue-simple-suggest>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import VueSimpleSuggest from 'vue-simple-suggest/dist/cjs';
import { mapState, mapGetters } from 'vuex';
import logger from 'utils/logger';

export default {
  data() {
    return {
      isVisible: false,
      term: '',
    };
  },
  components: { VueSimpleSuggest },
  computed: {
    ...mapState('generic', ['isScrolled']),
    ...mapGetters('generic', ['activeLocale'])
  },
  watch: {
    isScrolled() {
      if (this.isScrolled) {
        this.hide();
      }
    }
  },
  methods: {
    show() {
      this.isVisible = true;

      this.$nextTick(() => {
        this.$el.getElementsByTagName('input')[0].focus();
      });
    },
    hide() {
      this.isVisible = false;
    },
    toggle() {
      if (this.isVisible) {
        this.hide();
      } else {
        this.show();
      }
    },
    async suggest(term) {
      const cleanTerm = term && typeof term === 'string' ? term.trim().toLowerCase() : null;

      if (cleanTerm.length === 0) {
        return [];
      }

      try {
        const response = await axios
          .get(`/api/v1/video/go/autocomplete?term=${cleanTerm}`);

        return response.data.map((hit) => ({
          id: hit.key,
          title: hit.key,
          html: hit.key.replaceAll(cleanTerm, `<span class="t1-searchItem--highlight">${cleanTerm}</span>`)
        })).splice(0, 5);
      } catch (e) {
        logger.captureException(e);
        return [];
      }
    },
    selected(suggestion) {
      // Trigger actual search using the selected suggestion
      this.search(suggestion.title ?? this.term);
    },
    enter() {
      // Trigger search using the current term on enter if the suggestField is not hovered (no suggestions selected)
      if (!this.$refs.suggestField.hovered) {
        this.search(this.term);
      }
    },
    search(term) {
      term = term && typeof term === 'string' ? term.trim() : null;
      const locale = this.activeLocale.code === 'de_DE' ? 'de' : 'en';

      if (term && term.length > 0) {
        location.href = `https://www.guerzenich-orchester.de/${locale}/search/event/${term}`;
      }
    },
    handleBlur(e) {
      if (e.relatedTarget) {
        // Prevent default and re-focus the input element on search cta click
        if (e.relatedTarget === this.$refs.searchCta) {
          e.preventDefault();
          this.$el.getElementsByTagName('input')[0].focus();
          return;
        }

        // Do nothing on toggle button click (otherwise we would hide it here and re-show it through the toggle button click)
        if (e.relatedTarget === this.$refs.toggleCta) {
          return;
        }
      }

      this.hide();
    }
  }
};
</script>

<style lang="css">
.t1-siteSearch {
  @apply relative;
}

.t1-searchField {
  @apply absolute z-20 bottom-0 bg-mono-100 text-mono-0 border;
  left: calc(100% + 8px);
  transform: translate(-100%, 100%);
  width: 31.875rem;

  .vue-simple-suggest {
    @apply relative;

    > ul {
      @apply m-0;
    }

    .t1-searchField-inputWrapper {
      .t1-searchField-cta {
        @apply px-3 flex items-center justify-center border-l cursor-pointer;
        height: 24px;
      }
    }

    input {
      @apply text-2xl border-0 w-full;
      outline: 0 !important;
      box-shadow: none !important;

      &::-webkit-search-cancel-button {
        @apply relative w-4 h-4 p-2 cursor-pointer;
        -webkit-appearance: none;
        background-image: url('../../../img/icons/search-cancel.png');
        background-repeat: no-repeat;
        background-position: center;
        background-size: 50%;
      }
    }

    .suggestions {
      @apply relative bg-mono-100 z-50 text-2xl pt-3;

      &:before {
        content: "";
        height: 1px;
        width: 200px;
        @apply bg-mono-90 absolute top-1 left-3 block;
      }

      .suggest-item {
        @apply cursor-pointer py-2 px-3 font-light text-mono-50;

        &.selected, &.hover {
          @apply bg-mono-90;
        }

        .t1-searchItem--highlight {
          @apply text-mono-textBase font-bold;
        }
      }
    }
  }
}
</style>
