import * as types from './mutation-types';

export default {
  [types.CHANGE_VIEW](state, view) {
    state.view = view;
  },
  [types.SELECT_CATEGORY](state, category) {
    state.selectedCategory = category;
  },
  [types.UPDATE_CONSENT](state, consent) {
    Object.keys(consent).forEach((code) => {
      state.cookieNotice.categories[code].selected = consent[code];
    });
  },
  [types.SET_USER_SELECTED_STATE](state, selected) {
    state.userSelected = selected;
  },
  [types.SET_COOKIE_NOTICE_VISIBILITY](state, visibility) {
    state.cookieNoticeVisible = visibility;
  },
  [types.SET_COOKIE_NOTICE_MESSAGE](state, message) {
    state.cookieNoticeMessage = message;
  }
};
