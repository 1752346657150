<template>
  <div>
    <div
      v-if="filterConfig"
      class="flex flex-wrap justify-center -mx-2">
      <filter-element
        v-for="row in filterConfig"
        :key="row.key"
        :config="row" />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import './FilterElement';

export default {
  computed: {
    ...mapState('videoSearch', ['filterConfig'])
  },
};
</script>
