import { mapState, mapActions } from 'vuex';
import { shouldCloseOnClick } from 'utils';

export default {
  data() {
    return {
      isOpen: false
    };
  },
  computed: {
    ...mapState('generic', ['activeDropDownFilter'])
  },
  watch: {
    activeDropDownFilter(uid) {
      if (this._uid !== uid) this.hide();
    },
    isOpen(val) {
      if (val) {
        window.addEventListener('click', this.clickListener, false);
      } else {
        window.removeEventListener('click', this.clickListener, false);
      }
    }
  },
  methods: {
    ...mapActions('generic', ['setActiveDropDownFilter']),
    open() {
      this.isOpen = true;
      this.setActiveDropDownFilter(this._uid);
    },
    show() {
      this.open();
    },
    close() {
      this.isOpen = false;
    },
    closeOnClickOutside() {
      this.close();
    },
    hide() {
      this.close();
    },
    toggle() {
      if (this.isOpen) this.close();
      else this.open();
    },
    clickListener(e) {
      if (shouldCloseOnClick(e, this.containerClass)) { this.closeOnClickOutside(); }
    },
  }
};
