import * as types from './mutation-types';

export default {
  [types.SET_MEMBER](state, member) {
    state.member = member;
  },
  [types.SET_LOGIN_VISIBILITY](state, isVisible) {
    state.loginVisible = isVisible;
  },
  [types.SET_LOGIN_MESSAGE](state, message) {
    state.loginMessage = message;
  }
};
